var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { xs: "12", lg: "12" } },
            [
              _vm.projectData.length > 0
                ? _c(
                    "b-card",
                    [
                      _c("template", { slot: "title" }),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group ml-2",
                                  staticStyle: {
                                    width: "200px",
                                    float: "right"
                                  }
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.project_selected,
                                          expression: "project_selected"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      staticStyle: {
                                        border: "2px solid #c1c1c1"
                                      },
                                      attrs: { id: "vendorFilter" },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.project_selected = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          function($event) {
                                            return _vm.changeVendors(
                                              _vm.project_selected
                                            )
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { attrs: { value: "all" } },
                                        [_vm._v("all")]
                                      ),
                                      _vm._l(_vm.projectData, function(v, i) {
                                        return _c(
                                          "option",
                                          { domProps: { value: v.projectid } },
                                          [_vm._v(_vm._s(v.projectname))]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "form-group ml-2",
                                  staticStyle: {
                                    width: "200px",
                                    float: "right"
                                  }
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.vendorChange,
                                          expression: "vendorChange"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      staticStyle: {
                                        border: "2px solid #c1c1c1"
                                      },
                                      attrs: { id: "vendorFilter" },
                                      on: {
                                        change: [
                                          function($event) {
                                            var $$selectedVal = Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function(o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function(o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                            _vm.vendorChange = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          function($event) {
                                            return _vm.changeVendors(0)
                                          }
                                        ]
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: { selected: "", value: "all" }
                                        },
                                        [_vm._v("All Vendors Registered")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "used" } },
                                        [_vm._v("Vendors with services")]
                                      ),
                                      _c(
                                        "option",
                                        { attrs: { value: "unused" } },
                                        [_vm._v("Vendors without services")]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm.$refs.vendorTable
                                ? _c(
                                    "vue-json-to-csv",
                                    {
                                      staticClass:
                                        "btn float-right ml-3 btn-md btn-blue",
                                      attrs: {
                                        labels: {
                                          email: { title: "Email" },
                                          fname: { title: "First Name" },
                                          lname: { title: "Last Name" },
                                          company_name: {
                                            title: "Company Name"
                                          },
                                          creation_date: {
                                            title: "Registration Date"
                                          }
                                        },
                                        "csv-title": "Vendor_Export",
                                        "json-data":
                                          _vm.$refs.vendorTable.allFilteredData
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Download Table CSV/Excel\n                    "
                                      ),
                                      _c("i", { staticClass: "fa fa-download" })
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-client-table", {
                        ref: "vendorTable",
                        staticClass: "statusTable",
                        attrs: {
                          columns: _vm.columnsVendorServices,
                          data: _vm.wtsVendorList,
                          options: _vm.optionsVendorServices,
                          theme: _vm.theme,
                          id: "dataTable"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "status",
                              fn: function(props) {
                                return _c("div", {}, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-success w-100"
                                    },
                                    [_vm._v("Active")]
                                  )
                                ])
                              }
                            },
                            {
                              key: "fname",
                              fn: function(props) {
                                return _c("div", {}, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(props.row.fname) +
                                      " " +
                                      _vm._s(props.row.lname) +
                                      "\n              "
                                  )
                                ])
                              }
                            },
                            {
                              key: "company_name",
                              fn: function(props) {
                                return _c("div", {}, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.getVendorCompanyName(props.row.vid)
                                      ) +
                                      "\n              "
                                  )
                                ])
                              }
                            },
                            {
                              key: "creation_date",
                              fn: function(props) {
                                return _c("div", {}, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("moment")(
                                        props.row.creation_date,
                                        "MM-DD-YYYY hh:mm A"
                                      )
                                    )
                                  )
                                ])
                              }
                            },
                            {
                              key: "services",
                              fn: function(props) {
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "btn-sm",
                                        attrs: { variant: "primary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.loadVendorServices(
                                              props.row.vid
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", { staticClass: "fa fa-eye" }),
                                        _vm._v(" View Assigned Services")
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "edit",
                              fn: function(props) {
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName:
                                              "v-b-modal.editVendorEmail",
                                            modifiers: { editVendorEmail: true }
                                          }
                                        ],
                                        staticClass: "btn-sm",
                                        attrs: { variant: "success" },
                                        on: {
                                          click: function($event) {
                                            return _vm.sendVendorId(
                                              props.row.vid,
                                              props.row.email
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v("Edit Email "),
                                        _c("i", { staticClass: "fa fa-edit" })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }
                            },
                            {
                              key: "delete",
                              fn: function(props) {
                                return _c(
                                  "div",
                                  {},
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "b-modal",
                                            rawName: "v-b-modal.modal-10",
                                            modifiers: { "modal-10": true }
                                          }
                                        ],
                                        staticClass: "btn-sm",
                                        attrs: { variant: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.DeleteVendor(
                                              props.row.vid,
                                              props.row.email
                                            )
                                          }
                                        }
                                      },
                                      [_c("i", { staticClass: "fa fa-trash" })]
                                    )
                                  ],
                                  1
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          82379381
                        )
                      }),
                      _vm.serviceLength > 0
                        ? _c(
                            "b-progress",
                            {
                              staticClass: "mb-3",
                              staticStyle: {
                                "font-size": "0.8rem",
                                width: "300px",
                                float: "right"
                              },
                              attrs: {
                                max: _vm.serviceLength,
                                height: "1.5rem",
                                "show-value": "",
                                animated: ""
                              }
                            },
                            [
                              _c("b-progress-bar", {
                                attrs: {
                                  value: _vm.DeletedService,
                                  label:
                                    "Progress: " +
                                    _vm.DeletedService +
                                    "/" +
                                    _vm.serviceLength,
                                  variant: "success"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("br"),
                      _c(
                        "b-modal",
                        {
                          ref: "addService",
                          attrs: {
                            id: "addService",
                            title: "Add Service",
                            "title-tag": "h2",
                            centered: "",
                            size: "lg",
                            "hide-footer": ""
                          },
                          on: {
                            shown: function($event) {
                              return _vm.clearErrors()
                            }
                          }
                        },
                        [
                          _c(
                            "form",
                            {
                              staticClass: "pb-1",
                              attrs: { "data-vv-scope": "addService" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.addServiceValidator("addService")
                                }
                              }
                            },
                            [
                              _c(
                                "b-row",
                                { staticClass: "mb-3" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c("h5", [_vm._v("Select Project")]),
                                      _c("multiselect", {
                                        staticClass: "w-100",
                                        attrs: {
                                          placeholder: "Search For Project",
                                          "tag-placeholder":
                                            "Search For Project",
                                          "track-by": "projectid",
                                          options: _vm.allProjects,
                                          multiple: true,
                                          "close-on-select": false,
                                          "preserve-search": true,
                                          taggable: true,
                                          label: "projectname",
                                          "preselect-first": false
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.selectProject()
                                          }
                                        },
                                        model: {
                                          value: _vm.selected_projects,
                                          callback: function($$v) {
                                            _vm.selected_projects = $$v
                                          },
                                          expression: "selected_projects"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Service Name: ")
                                      ]),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            [
                                              _c("b-input-group-text", [
                                                _c("i", {
                                                  staticClass: "fa fa-hashtag"
                                                })
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "name",
                                              placeholder: "Service Name"
                                            },
                                            model: {
                                              value: _vm.name,
                                              callback: function($$v) {
                                                _vm.name = $$v
                                              },
                                              expression: "name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "addService.name"
                                            ),
                                            expression:
                                              "errors.has('addService.name')"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-exclamation-triangle"
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "addService.name"
                                              ),
                                              expression:
                                                "errors.has('addService.name')"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "The service name is required."
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Service Code: ")
                                      ]),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            [
                                              _c("b-input-group-text", [
                                                _c("i", {
                                                  staticClass: "fa fa-hashtag"
                                                })
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "type",
                                              placeholder: "Service Code"
                                            },
                                            model: {
                                              value: _vm.type,
                                              callback: function($$v) {
                                                _vm.type = $$v
                                              },
                                              expression: "type"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "addService.type"
                                            ),
                                            expression:
                                              "errors.has('addService.type')"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-exclamation-triangle"
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "addService.type"
                                              ),
                                              expression:
                                                "errors.has('addService.type')"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "The service code is required."
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { md: "12" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-right": "30px"
                                          }
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticStyle: {
                                                "margin-right": "5px"
                                              }
                                            },
                                            [_vm._v("Uom is volume ? :")]
                                          ),
                                          _c("input", {
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: _vm.uom_volume_status
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.uom_volume_status = !_vm.uom_volume_status
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _c("span", [
                                        _c(
                                          "b",
                                          {
                                            staticStyle: {
                                              "margin-right": "5px"
                                            }
                                          },
                                          [_vm._v("Service is Flexible ? :")]
                                        ),
                                        _c("input", {
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: _vm.isServiceFlexible
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.isServiceFlexible = !_vm.isServiceFlexible
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("UOM: "),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover",
                                              modifiers: { hover: true }
                                            }
                                          ],
                                          staticClass: "fa fa-question-circle",
                                          attrs: {
                                            title: "Unit Of Measurement"
                                          }
                                        })
                                      ]),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            [
                                              _c("b-input-group-text", [
                                                _c("i", {
                                                  staticClass: "fa fa-hashtag"
                                                })
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("b-form-input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              name: "uom1",
                                              disabled: _vm.isServiceFlexible,
                                              placeholder: ""
                                            },
                                            model: {
                                              value: _vm.uom1,
                                              callback: function($$v) {
                                                _vm.uom1 = $$v
                                              },
                                              expression: "uom1"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "addService.uom1"
                                            ),
                                            expression:
                                              "errors.has('addService.uom1')"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-exclamation-triangle"
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "addService.uom1"
                                              ),
                                              expression:
                                                "errors.has('addService.uom1')"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "The unit of measurement required."
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-1",
                                      attrs: { cols: "6" }
                                    },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Price / Unit: ")
                                      ]),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            [
                                              _c("b-input-group-text", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-dollar-sign"
                                                })
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "decimal:3",
                                                expression: "'decimal:3'"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isServiceFlexible,
                                              name: "pu1",
                                              placeholder: ""
                                            },
                                            model: {
                                              value: _vm.pu1,
                                              callback: function($$v) {
                                                _vm.pu1 = $$v
                                              },
                                              expression: "pu1"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "addService.pu1"
                                            ),
                                            expression:
                                              "errors.has('addService.pu1')"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-exclamation-triangle"
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "addService.pu1"
                                              ),
                                              expression:
                                                "errors.has('addService.pu1')"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "The price is required. Numbers only. Up to 3 decimals only."
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.uom2option === 0
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn mt-2 float-right btn-success",
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.uom2option = 1
                                                }
                                              }
                                            },
                                            [_vm._v("Add Another UOM")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.uom2option === 1
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v("UOM 2: ")
                                          ]),
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c("b-input-group-text", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-hashtag"
                                                    })
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("b-form-input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  name: "uom2",
                                                  placeholder: ""
                                                },
                                                model: {
                                                  value: _vm.uom2,
                                                  callback: function($$v) {
                                                    _vm.uom2 = $$v
                                                  },
                                                  expression: "uom2"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.uom2option === 1
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v("Price / Unit: ")
                                          ]),
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c("b-input-group-text", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-dollar-sign"
                                                    })
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "decimal:3",
                                                    expression: "'decimal:3'"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  name: "pu2",
                                                  placeholder: ""
                                                },
                                                model: {
                                                  value: _vm.pu2,
                                                  callback: function($$v) {
                                                    _vm.pu2 = $$v
                                                  },
                                                  expression: "pu2"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.errors.has(
                                                  "addService.pu2"
                                                ),
                                                expression:
                                                  "errors.has('addService.pu2')"
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-exclamation-triangle"
                                          }),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.errors.has(
                                                    "addService.pu2"
                                                  ),
                                                  expression:
                                                    "errors.has('addService.pu2')"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                " Numbers only. Ex: $59.99"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.uom2option === 1 && _vm.uom3option === 0
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "btn mt-2 float-right btn-success",
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.uom3option = 1
                                                }
                                              }
                                            },
                                            [_vm._v("Add Another UOM")]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.uom3option === 1
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v("UOM 3: ")
                                          ]),
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c("b-input-group-text", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-hashtag"
                                                    })
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("b-form-input", {
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  name: "uom3",
                                                  placeholder: ""
                                                },
                                                model: {
                                                  value: _vm.uom3,
                                                  callback: function($$v) {
                                                    _vm.uom3 = $$v
                                                  },
                                                  expression: "uom3"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.uom3option === 1
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass: "mb-1",
                                          attrs: { cols: "6" }
                                        },
                                        [
                                          _c("label", { attrs: { for: "" } }, [
                                            _vm._v("Price / Unit: ")
                                          ]),
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mb-2" },
                                            [
                                              _c(
                                                "b-input-group-prepend",
                                                [
                                                  _c("b-input-group-text", [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-dollar-sign"
                                                    })
                                                  ])
                                                ],
                                                1
                                              ),
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "decimal:3",
                                                    expression: "'decimal:3'"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  name: "pu3",
                                                  placeholder: ""
                                                },
                                                model: {
                                                  value: _vm.pu3,
                                                  callback: function($$v) {
                                                    _vm.pu3 = $$v
                                                  },
                                                  expression: "pu3"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c("i", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.errors.has(
                                                  "addService.pu3"
                                                ),
                                                expression:
                                                  "errors.has('addService.pu3')"
                                              }
                                            ],
                                            staticClass:
                                              "fa fa-exclamation-triangle"
                                          }),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.errors.has(
                                                    "addService.pu3"
                                                  ),
                                                  expression:
                                                    "errors.has('addService.pu3')"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "The price is required. Numbers only. Ex: $59.99"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v("Currency")
                                      ]),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            [
                                              _c("b-input-group-text", [
                                                _c("i", {
                                                  staticClass:
                                                    "fa fa-dollar-sign"
                                                })
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-select",
                                            {
                                              model: {
                                                value: _vm.currency,
                                                callback: function($$v) {
                                                  _vm.currency = $$v
                                                },
                                                expression: "currency"
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "CAD",
                                                    selected: ""
                                                  }
                                                },
                                                [_vm._v("CAD")]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "USD" } },
                                                [_vm._v("USD")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has(
                                              "addService.currency"
                                            ),
                                            expression:
                                              "errors.has('addService.currency')"
                                          }
                                        ],
                                        staticClass:
                                          "fa fa-exclamation-triangle"
                                      }),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.errors.has(
                                                "addService.currency"
                                              ),
                                              expression:
                                                "errors.has('addService.currency')"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "The price is required. Numbers only. Ex: $59.99"
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            variant: "success",
                                            type: "submit",
                                            block: ""
                                          }
                                        },
                                        [
                                          _vm._v("Create Service "),
                                          _c("i", { staticClass: "fa fa-plus" })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.error,
                                    expression: "error"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle mt-3"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.error,
                                      expression: "error"
                                    }
                                  ],
                                  staticClass: "help is-danger"
                                },
                                [
                                  _vm._v(
                                    "Service error. Cannot have duplicates. Please contact admin."
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-modal",
                        {
                          ref: "editVendorEmail",
                          attrs: {
                            id: "editVendorEmail",
                            title: "Edit Vendor Email",
                            "title-tag": "h2",
                            centered: "",
                            size: "lg",
                            "hide-footer": ""
                          }
                        },
                        [
                          _c(
                            "form",
                            {
                              staticClass: "pb-2",
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.emailvalidateBeforeSubmit(
                                    "editemail"
                                  )
                                }
                              }
                            },
                            [
                              _c("p", { staticClass: "text-muted" }, [
                                _vm._v("Enter new email.")
                              ]),
                              _c(
                                "b-row",
                                { staticClass: "mb-3" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { md: "12" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-input-group-prepend",
                                            [
                                              _c("b-input-group-text", [
                                                _c("i", {
                                                  staticClass: "fa fa-envelope"
                                                })
                                              ])
                                            ],
                                            1
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.email,
                                                expression: "email"
                                              }
                                            ],
                                            ref: "email",
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "email",
                                              placeholder: "Email Address",
                                              name: "email"
                                            },
                                            domProps: { value: _vm.email },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.email = $event.target.value
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.emailerror,
                                    expression: "emailerror"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.emailerror,
                                      expression: "emailerror"
                                    }
                                  ],
                                  staticClass: "help is-danger"
                                },
                                [_vm._v("Error. Please try again.")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-4",
                                  attrs: {
                                    variant: "success",
                                    type: "submit",
                                    block: ""
                                  }
                                },
                                [_vm._v("Edit Email")]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("WtsImport", {
                        attrs: {
                          id: "scrollToServices",
                          update: _vm.update,
                          name: _vm.vcnProp,
                          vid: _vm.vid,
                          pid: _vm.project_selected
                        }
                      })
                    ],
                    2
                  )
                : _c("b-card", [_c("div", [_vm._v("No Project Assigned.")])])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }